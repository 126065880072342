.ant-modal-header {
  position: relative;
  border-bottom: 1px dotted #f5f5f5;
  margin-bottom: 30px !important;
}

.ant-modal-header .ant-modal-title {
  font-size: 30px;
}

.ant-modal-body .form-detail label.form-label,
.ant-modal-body .form-detail p {
  font-size: 20px;
}

.ant-modal-content {
  padding: 20px 42px !important;
}

.ant-table  table {
  font-size: 13px;
}

.ant-table table thead.ant-table-thead tr th {
  background-color: #F6F7FC !important;
  color: #667988;
  font-weight: 500;
}


/* .ant-table table tbody.ant-table-tbody tr td:first-child {
  width: 102px !important;
}

.ant-table table tbody.ant-table-tbody tr td:nth-child(2){
  width: 116px !important;
}
.ant-table table tbody.ant-table-tbody tr td:nth-child(3){
  width: 180px !important;
}
.ant-table table tbody.ant-table-tbody tr td:nth-child(4){
  width: 10% !important;
}
.ant-table table tbody.ant-table-tbody tr td:nth-child(5){
  width: 14% !important;
}
.ant-table table tbody.ant-table-tbody tr td:nth-child(6){
  width: 14% !important;
}
.ant-table table tbody.ant-table-tbody tr td:last-child{
  width: 10% !important;
} */

.btn-outline-primary {
  border-radius: 12px !important;
  height: 43px;
  width: 129px;
  align-items: center !important;
  padding-top: 9px !important;
}
.btn-location {
  width: 170px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}


.btn-outline-primary:hover {
  background-color: transparent !important;
  color: #039BE5 !important;
}